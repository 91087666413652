'use strict';

function removeOtherCookies (pCookieToLive) {
    if (Array.isArray(pCookieToLive) === false) {
        pCookieToLive = [];
    }

    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        var cookieKey = pair[0].trim();

        if (typeof cookieKey === "string" && (cookieKey.length > 0) && (pCookieToLive.indexOf(cookieKey) === -1)) {
            $.cookie(cookieKey, null); // remove it
        }
    }
}

function doMultiScopeLogin(pUsername, pPassword, pScope, pSuccess, pFailure) {
    doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure);
}

function doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure) {
    removeOtherCookies(['user_id', 'user_hash', 'expiration_date', 'csrf-token', 'cf_sidebar_displaymode']);
    var login_options = { "password": pPassword};
    if (pScope !== "") {
        login_options.scope_name = pScope;
    }
    api_async.auth.login_with_options(pUsername, login_options, function (pData) {
        if (pData.user_id === undefined) {
            failedLogin($.i18n._('nixps-cloudflow-login.error-login_failed'));
        } else {
            // Store our login in a cookie
            var secureOptions = { 
                expires: 2, // default 2 days
                path: '/' 
            };
            if (document.location.protocol === 'https:') {
                secureOptions.secure = true;
            }
            if (pData.expiration_date !== undefined) {
                secureOptions.expires = (pData.expiration_date - new Date().getTime() / 1000) / 86400 + 1;
            }
            $.cookie('user_id', pData.user_id, secureOptions);
            $.cookie('user_hash', pData.user_hash, secureOptions);
            $.cookie('expiration_date', pData.expiration_date, secureOptions);
            $.cookie('csrf-token', pData.csrf_token, secureOptions);
            if (pData.scope_id !== undefined) {
                $.cookie('scope', pData.scope_id, { expires: secureOptions.expires, path: '/' });
            } 
            pSuccess(pData.user_id);
        }
    }, function (pError) {
        if (pError.error_code !== undefined) {
            pFailure($.i18n._('nixps-cloudflow-login.error-' + pError.error_code));
        } else if (pError.error != undefined) {
            pFailure(pError.error);
        }
    });
}

function doLogin (pUsername, pPassword, pSuccess, pFailure) {
    doLoginWithOptions(pUsername, pPassword, "" ,pSuccess, pFailure);
}

function getVersionText (pVersionData) {
    var version = pVersionData.major + "." + pVersionData.minor;

    if (pVersionData.rev !== 0) {
        version += "." + pVersionData.rev;
    }

    var versionText = $.i18n._('nixps-cloudflow-login.version', [version, pVersionData.build]);
    return versionText;
}

function setFrameSession (userId) {
    return $.Deferred(function (pDefer) {
        api_defer.frame.os.get().then(function () {
            api_defer.auth.get_current_user().then(function (currentUser) {
                return api_defer.auth.create_session(currentUser.username);
            }).then(function (sessionKey) {
                var session = sessionKey.session;
                return api_defer.frame.os.set_cloudflow_session(session, {"force": true, "expiry_time": sessionKey.expiry_time});
            }).then(function () {
                pDefer.resolve();
            }).fail(function () {
                pDefer.resolve();
            });
        }).fail(function () {
            pDefer.resolve();
        });
    });
}

module.exports = {
    doLogin: doLogin,
    doMultiScopeLogin: doMultiScopeLogin,
    getVersionText: getVersionText,
    setFrameSession: setFrameSession
}
