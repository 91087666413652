'use strict';
var loginFunctions = require('./loginUtil.js');
var Version = require('../../../cloudflow/Version/js/Version.js');
/**
 * Handler that is executed when the login has failed
 */
function loginFailedHandler (pReason) {
    $('#loginStatus').text(pReason);
    $('#loginButton').prop('disabled', false);
    $('#loginStatus').closest('.cf-form-group').addClass('cf-form-group-has-error');
}

/**
 * Handler that does the login
 */
function loginHandler () {
    $('#loginButton').prop('disabled', true);
    var username = $('#username').val();
    var password = $('#password').val();
    $('#loginButton').prop('disabled', true);
    $('#loginStatus').closest('.cf-form-group').removeClass('cf-form-group-has-error');
    if ($('#scope_options').length > 0) {
        var scope_option = $('#scope_options').val();
        loginFunctions.doMultiScopeLogin(username, password, scope_option, function (pUserId) {
            loginFunctions.setFrameSession(pUserId).then(function () {
                document.location.assign(sOrigURL);
            });
        }, loginFailedHandler);
    } else {
        loginFunctions.doLogin(username, password, function (pUserId) {
            loginFunctions.setFrameSession(pUserId).then(function () {
                document.location.assign(sOrigURL);
            });
        }, loginFailedHandler);
    }
}

/**
 * Draws the UI and binds the events
 * @param {string} pLanguage 
 */
function renderLogin (pLanguage, pVersionObject) {
    document.title = $.i18n._(sTitle);
    $('#username').attr('placeholder', $.i18n._('nixps-cloudflow-login.username'));
    $('#password').attr('placeholder', $.i18n._('nixps-cloudflow-login.password'));

    // Set defaults in case the variables are not filled in
    if (sOrigURL === "<!--ORIG-->") {
        sOrigURL = window.location.href;
    }

    if (sOrigUser === "<!--USERNAME-->") {
        sOrigUser = "";
    }

    if (sOrigUser !== "") {
        $('#username').val(sOrigUser);
    }

    // Try to generate the oauth2 link
    api_defer.auth.generate_oauth2_url('google').then(function (pData) {
        if ((pData.result !== undefined) && (pData.result !== null)) {
            $('a#googleButton').attr('href', pData.result);
            $('div#google').show();
        }
    }, function () {
        /* ignore error */
    });

    // Bind the login button
    $('#loginButton').attr('value', $.i18n._('nixps-cloudflow-login.ok'))
        .prop("disabled", false)
        .css("cursor", "pointer")
        .click(loginHandler);

    // Focus the right field
    if ($('#username').val() === '') {
        $('#username').focus();
    } else {
        $('#password').focus();
    }

    // Bind the return keypress
    $(document).keypress(function (pEvent) {
        if (pEvent.keyCode === 13) {
            loginHandler();
        }
    });

    // Show the version text
    if (pVersionObject === undefined) {
        Version.get().then(function(version){
            var loginText = loginFunctions.getVersionText(version.getVersionObject());
            $("#versionText").text(loginText);
        });
    } else {
        var loginText = loginFunctions.getVersionText(pVersionObject);
		$("#versionText").text(loginText);
    }

    if (window.location.toString().indexOf('error=1') >= 0) {
        loginFailedHandler($.i18n._('nixps-cloudflow-login.error-user_not_registered'));
    }
}
//check if user has multiple scopes and add dropdown if so
function check_scopes() {
        api_async.users.get_all_scopes_for_user($('#username').val(), function(list_scopes){
        $('#scope_options').parent('.cf-form-group').remove();
        if (list_scopes !== undefined && list_scopes.scopes && list_scopes.scopes.length > 1) {
            if ($('#scope_options').length === 0) {
                $('<div class="cf-form-group cf-form-add-gutter"><select class="cf-form-control cf-form-col-12" id="scope_options"></select></div>').insertAfter($('#password').parent('.cf-form-group'));
            }
            list_scopes.scopes.forEach(function (scope, index) {
                if (index === 0) {
                    $('#scope_options').append('<option value="' + scope.name + '" selected>' + scope.name + '</option>');
                }
                else {
                    $('#scope_options').append('<option value="' + scope.name + '">' + scope.name + '</option>');
                }
    
            });
        }
    });

}

/**
 * Entry point of the login page
 */
$(function () {
    /* reset sessions */
    if (window.sessionStorage !== undefined) {
        sessionStorage.clear();
    }
    
    /* check if user has multiple scopes when leaving username field */
    $('#username').on("focusout", function () {
        var username = $("#username").val();
        check_scopes(username);
    });

    var prefsPromise = api_defer.preferences.get_for_current_user('', 'language');
    $.when(prefsPromise, Version.get()).then(function (prefs, version) {
        var language = prefs.preferences;
        var build = version.getBuild();
        return $.when($.get('/cloudflow_' + language + '.json?' + build), language, version.getVersionObject());
    }).then(function (translations, language, pVersionObject) {
        $.i18n.setDictionary(translations[0]);
        $('body').find("span.translate").each(function(index, element) {
            var e = $(element);
            e._t(e.attr('key'));
        });
        renderLogin(language, pVersionObject);
    }).fail(function () {
        $.get('/cloudflow_en.json?' + (new Date().getTime())).then(function(p_translations) {
            $.i18n.setDictionary(p_translations);
            $('body').find("span.translate").each(function(index, element) {
                var e = $(element);
                e._t(e.attr('key'));
            });
            renderLogin('en');
        }).fail(function (pError) {
            console.error(pError);
		    // even translations faild, so we can not translate error message :-(
            $("#loginStatus").text("Unable to setup log in. Retry later.").closest('.cf-form-group').addClass('cf-form-group-has-error');
        });
    });
});
